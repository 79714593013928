import React, { useEffect, useState } from 'react'
import AgoraUIKit, { layout,  } from 'agora-react-uikit'
import 'agora-react-uikit/dist/index.css'

const App = () => {
  const [videocall, setVideocall] = useState(true)
  const [isHost, setHost] = useState(true)
  const [isPinned, setPinned] = useState(false)
  const [username, setUsername] = useState('')
  const [channelName, setChannel] = useState('')

  const search = () => {
    const params = new URLSearchParams(window.location.search)
    const username = params.get('name')
    const code = params.get('code')
    if (username) {
      setUsername(username)
    }
    if (code) {
      setChannel(code);
    }
  }

  useEffect(() => {
    search()
  }, [])
  
  if(channelName) {
    return (
      <div style={styles.container}>
        <div style={styles.videoContainer}>
          {videocall ? (<>
            <div style={styles.nav}>
              {/* <p style={styles.btn} onClick={() => setHost(!isHost)}>Change Role</p>
              <p style={styles.btn} onClick={() => setPinned(!isPinned)}>Change Layout</p> */}
            </div>
            <div style={{ position: 'absolute', width: 50, height: 50, top: 10, left: 10, zIndex: 9999 }}>
              <img src='/logo.png' style={{ borderRadius: 5 }}/>
            </div>
            <AgoraUIKit
              rtcProps={{
                appId: 'a08925af78f6414899b10f8e04d8a442',
                channel: channelName,
                token: null, //add your token if using app in secured mode
                role: isHost ? 'host' : 'audience',
                layout: isPinned ? layout.pin : layout.grid
              }}
              styleProps={{
                localBtnContainer: {
                  background: '#fff',
                  padding: '10px 20px 10px 20px',
                  borderRadius: '8px',
                  bottom: 10,
                  display: 'flex',
                  position: 'absolute',
                  right: 0,
                  left: 0,
                  margin: '0 auto',
                  width: 'fit-content',
                  gap: '20px',
                },
              }}
              rtmProps={{username: username || 'user', displayUsername: false}}
              callbacks={{
                EndCall: () => setVideocall(false),
              }} /></>
          ) : (
            <div style={styles.nav}>
                <input style={styles.input} placeholder='nickname' type="text" value={username} onChange={(e) => { setUsername(e.target.value) }} />
              <h3 style={styles.btn} onClick={() => setVideocall(true)}>Start Call</h3>
            </div>
          )}
        </div>
      </div>
    )
  } else {
    return <div/>
  }
}

const styles = {
  container: { width: '100vw', height: '100vh', display: 'flex', flex: 1, backgroundColor: '#007bff22'},
  heading: { textAlign: 'center', marginBottom: 0 },
  videoContainer: { display: 'flex', flexDirection: 'column', flex: 1 },
  nav: { display: 'flex', justifyContent: 'space-around' },
  btn: { backgroundColor: '#007bff', cursor: 'pointer', borderRadius: 5, padding: '4px 8px', color: '#ffffff', fontSize: 20 },
  input: {display: 'flex', height: 24, alignSelf: 'center'}
}

export default App